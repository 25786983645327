import { useDownloadStore } from "features/download/store"
import { WHOLE_GALLERY } from "features/download/contstants"

function useDownloadGallery(galleryId) {
  const showDownloadModal = useDownloadStore((store) => store.showDownloadModal)
  const setDownloadTarget = useDownloadStore((store) => store.setDownloadTarget)

  function downloadGallery() {
    setDownloadTarget({ target: WHOLE_GALLERY, id: galleryId })
    showDownloadModal()
  }

  return downloadGallery
}

export { useDownloadGallery }
