import create, { State } from "zustand"
import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"

interface ProductModalStore extends State {
  activeModal: null | PRODUCTS_MODAL_TYPES
  show: (modalName: PRODUCTS_MODAL_TYPES) => void
  hide: () => void
}

const useProductsModalStore = create<ProductModalStore>((set) => ({
  activeModal: null,
  show: (modalName: PRODUCTS_MODAL_TYPES) => set(() => ({ activeModal: modalName })),
  hide: () => set(() => ({ activeModal: null })),
}))

export { useProductsModalStore }
