import { api } from "@app/api"

import { DEFAULT_IMAGE_RESOLUTION_DOWNLOAD_TYPE, DOWNLOAD_PROVIDER_YANDEX } from "features/download/contstants"

async function downloadToCloud({
  entityType,
  ids,
  token,
  type = DEFAULT_IMAGE_RESOLUTION_DOWNLOAD_TYPE,
  provider = DOWNLOAD_PROVIDER_YANDEX,
}) {
  try {
    const response = await api().post(`/media-files/copy-to-cloud-drive`, {
      entityType,
      ids,
      token,
      type,
      provider,
    })
    return response.data
  } catch (error) {
    console.error(error.response)

    return null
  }
}

export { downloadToCloud }
