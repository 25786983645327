import { DOWNLOAD_SCENE_TYPE } from "../contstants"

function getSelectedScenes(formElements): Array<string> | DOWNLOAD_SCENE_TYPE.ALL_PHOTOS | void {
  if (!formElements) return

  const checkboxes = [...formElements].filter(
    (formElement) =>
      formElement.name === DOWNLOAD_SCENE_TYPE.ALL_PHOTOS || formElement.name === DOWNLOAD_SCENE_TYPE.SCENE
  )

  if (!checkboxes || checkboxes.length === 0) {
    return
  }

  const selectedScenes = checkboxes.filter((selectedScene) => selectedScene.checked)

  const isAllScenesCheckboxSelected =
    selectedScenes.length === 1 && selectedScenes[0].name === DOWNLOAD_SCENE_TYPE.ALL_PHOTOS

  if (isAllScenesCheckboxSelected) {
    return DOWNLOAD_SCENE_TYPE.ALL_PHOTOS
  }

  return checkboxes.filter((selectedScene) => selectedScene.checked).map((selectedScene) => selectedScene.id)
}

export { getSelectedScenes }
