import create from "zustand"

import { DEFAULT_IMAGE_RESOLUTION_DOWNLOAD_TYPE } from "features/download/contstants"

const useDownloadStore = create((set) => ({
  downloadableImageResolution: DEFAULT_IMAGE_RESOLUTION_DOWNLOAD_TYPE,
  setDownloadableImageResolution: (settings) => set(() => ({ downloadableImageResolution: settings })),
  resetDownloadableImageResolution: () =>
    set(() => ({ downloadableImageResolution: DEFAULT_IMAGE_RESOLUTION_DOWNLOAD_TYPE })),

  downloadTarget: null,
  setDownloadTarget: (target) => set(() => ({ downloadTarget: target })),
  resetDownloadTarget: () => set(() => ({ downloadTarget: null })),

  isDownloadModalVisible: false,
  showDownloadModal: () => set(() => ({ isDownloadModalVisible: true })),
  hideDownloadModal: () => set(() => ({ isDownloadModalVisible: false })),

  downloadToCloudStatus: null,
  setDownloadToCloudStatus: (target) => set(() => ({ downloadToCloudStatus: target })),
  resetDownloadToCloudStatus: () => set(() => ({ downloadToCloudStatus: null })),

  selectedPhotoType: DEFAULT_IMAGE_RESOLUTION_DOWNLOAD_TYPE,
  setSelectedPhotoType: (target) => set(() => ({ selectedPhotoType: target })),
  resetSelectedPhotoType: () => set(() => ({ selectedPhotoType: DEFAULT_IMAGE_RESOLUTION_DOWNLOAD_TYPE })),
}))

export { useDownloadStore }
