import { useProductsModalStore } from "@app/features/products/store/products-modal"
import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"

/**
 * Отображение модального окна с печатной продукцией
 *
 * Получает метод из store переключающий состояние модального окна в видимое и возвращает в качестве функции
 *
 * @since  0.0.1
 * @alias  useShowProductsModal
 * @see    Function
 *
 * @return {Function} Функция переключающая состояние отображения модального окна в видимое
 */
const useShowProductsModal = () => {
  return useProductsModalStore((state) => state.show)
}

/**
 * Скрытие модального окна с печатной продукцией
 *
 * Получает метод из store переключающий состояние модального окна в скрытое и возвращает в качестве функции
 *
 * @since  0.0.1
 * @alias  useHideProductsModal
 * @see    Function
 *
 * @return {Function} Функция переключающая состояние отображения модального окна в скрытое
 */
const useHideProductsModal = () => {
  return useProductsModalStore((state) => state.hide)
}

/**
 * Получение текущего состояния видимости модального окна
 *
 * Возвращает boolean переменную состояния модального окна
 *
 * @since  0.0.1
 * @alias  useHideProductsModal
 * @see    Function
 *
 * @return {boolean} Текущее состояние видимости модального окна
 */
const useIsVisibleProductsModal = (modalName: PRODUCTS_MODAL_TYPES) => {
  const $activeModal = useProductsModalStore((state) => state.activeModal)
  return modalName === $activeModal
}

export { useShowProductsModal, useHideProductsModal, useIsVisibleProductsModal }
