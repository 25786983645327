import { useDownloadStore } from "features/download/store"
import { SINGLE_VIDEO } from "features/download/contstants"

function useDownloadVideo(id, sizes = []) {
  const showDownloadModal = useDownloadStore((store) => store.showDownloadModal)
  const setDownloadTarget = useDownloadStore((store) => store.setDownloadTarget)

  function downloadVideo() {
    setDownloadTarget({ target: SINGLE_VIDEO, id, sizes })
    showDownloadModal()
  }

  return downloadVideo
}

export { useDownloadVideo }
