import { downloadToCloud } from "features/download/utils"

import { useDownloadModal } from "features/download/store/hooks"
import { useLocalstorageListener } from "features/download/hooks"
import { yandexOAuth } from "features/download/utils"

import {
  FAVORITE_PHOTOS,
  YANDEX_CLOUD_TOKEN,
  DOWNLOAD_LOADING,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_ERROR,
  FAVORITE_LIST,
} from "features/download/contstants"

function useDownloadToYandex(serviceDomain) {
  const { $setDownloadToCloudStatus, $setSelectedPhotoType, $setDownloadTarget } = useDownloadModal()

  async function downloadToYandex({ target, targetId, selectedPhotoType }) {
    $setDownloadToCloudStatus(DOWNLOAD_LOADING)
    $setSelectedPhotoType(selectedPhotoType)

    const yandexToken = localStorage.getItem(YANDEX_CLOUD_TOKEN)

    if (yandexToken) {
      const response = await downloadToCloud({
        entityType: target === FAVORITE_PHOTOS ? FAVORITE_LIST : target,
        ids: Array.isArray(targetId) ? targetId : [targetId],
        token: yandexToken,
        type: selectedPhotoType,
      })
      if (response?.success) {
        $setDownloadToCloudStatus(DOWNLOAD_SUCCESS)
      } else {
        $setDownloadToCloudStatus(DOWNLOAD_ERROR)
      }
      return
    }

    if (!yandexToken) {
      const hasSeveralScenesToDownload = targetId.length > 1
      if (hasSeveralScenesToDownload) {
        $setDownloadTarget({ target: "scenes", id: targetId })
      }
      const oAuthRedirectUri = process.env.NEXT_PUBLIC_YANDEX_DISK_OAUTH_REDIRECT_URI
      yandexOAuth({
        serviceDomain: serviceDomain,
        oAuthRedirectUri,
        $setDownloadToCloudStatus,
      })
    }
  }
  useLocalstorageListener(downloadToYandex)

  return downloadToYandex
}

export { useDownloadToYandex }
