import React from "react"

import { useDownloadModal } from "features/download/store/hooks"

import { YANDEX_CLOUD_OAUTH_DENIED, YANDEX_CLOUD_TOKEN, DOWNLOAD_ERROR } from "features/download/contstants"

function useLocalstorageListener(downloadToYandex) {
  const { $downloadTarget, $setDownloadToCloudStatus, $selectedPhotoType } = useDownloadModal()

  async function handleLocalStorageChange(event) {
    if (!$downloadTarget) {
      return
    }
    const isValueExist = event.newValue !== null
    const isYandexTokenExist = event.key === YANDEX_CLOUD_TOKEN
    if (isValueExist && isYandexTokenExist) {
      await downloadToYandex({
        target: $downloadTarget.target,
        targetId: $downloadTarget.id,
        selectedPhotoType: $selectedPhotoType,
        $setDownloadToCloudStatus,
      })
    }

    const isYandexOAuthDenied = event.key === YANDEX_CLOUD_OAUTH_DENIED
    if (isValueExist && isYandexOAuthDenied) {
      $setDownloadToCloudStatus(DOWNLOAD_ERROR)
    }
  }

  React.useEffect(() => {
    window.addEventListener("storage", handleLocalStorageChange)

    return () => window.removeEventListener("storage", handleLocalStorageChange)
  }, [$selectedPhotoType, $downloadTarget])
}

export { useLocalstorageListener }
