const DOWNLOAD_TO_GOOGLE_TYPE: string = "downloadToGoogle"
const DOWNLOAD_TO_GOOGLE_STARTED: string = "downloadToGoogleStarted"
const DOWNLOAD_TO_GOOGLE_SUCCEED: string = "downloadToGoogleSucceed"
const DOWNLOAD_TO_GOOGLE_ERRORED: string = "downloadToGoogleErrored"
const DOWNLOAD_TO_GOOGLE_TRIGGERED: string = "downloadToGoogleTrigger"

export {
  DOWNLOAD_TO_GOOGLE_TYPE,
  DOWNLOAD_TO_GOOGLE_STARTED,
  DOWNLOAD_TO_GOOGLE_SUCCEED,
  DOWNLOAD_TO_GOOGLE_ERRORED,
  DOWNLOAD_TO_GOOGLE_TRIGGERED,
}
