import React from "react"

import { sendMessageToParentFrame } from "@app/utils"
import { loadGoogleScript } from "@app/utils"

import {
  GOOGLE_CREDENTIALS_SCOPE,
  DOWNLOAD_TO_GOOGLE_ERRORED,
  DOWNLOAD_TO_GOOGLE_TRIGGERED,
} from "@app/features/download/contstants"

function useGoogleSignIn() {
  const googleAuth = React.useRef(null)

  async function googleSignIn() {
    const parentOrigin = /origin=([^&]+)/.exec(window.location.href)[1]

    return (
      googleAuth.current &&
      googleAuth.current
        .signIn()
        .then((response) => {
          const accessToken = response.getAuthResponse().access_token
          sendMessageToParentFrame({ type: DOWNLOAD_TO_GOOGLE_TRIGGERED, accessToken }, parentOrigin)
        })
        .catch((error) => {
          sendMessageToParentFrame({ type: DOWNLOAD_TO_GOOGLE_ERRORED }, parentOrigin)
          console.log(error)

          return null
        })
    )
  }

  React.useEffect(() => {
    // Window.gapi is available at this point

    window.onGoogleScriptLoad = () => {
      const _gapi = window.gapi

      _gapi.load("auth2", () => {
        ;(async () => {
          const _googleAuth = await _gapi.auth2.init({
            client_id: process.env.NEXT_PUBLIC_GOOGLE_APP_ID,
            scope: GOOGLE_CREDENTIALS_SCOPE,
          })
          googleAuth.current = _googleAuth
        })()
      })
    }

    // Ensure everything is set before loading the script
    loadGoogleScript() // (Ref. 9)

    return () => (window.onGoogleScriptLoad = null)
  }, [])

  return { googleSignIn }
}

export { useGoogleSignIn }
