import { useDownloadStore } from "features/download/store"
import { SINGLE_PHOTO } from "features/download/contstants"

function useDownloadPhoto(photoId, sizes) {
  const showDownloadModal = useDownloadStore((store) => store.showDownloadModal)
  const setDownloadTarget = useDownloadStore((store) => store.setDownloadTarget)

  function downloadPhoto() {
    setDownloadTarget({ target: SINGLE_PHOTO, id: photoId, sizes })
    showDownloadModal()
  }

  return downloadPhoto
}

export { useDownloadPhoto }
