import { YANDEX_CLOUD_TOKEN, DOWNLOAD_LOADING, DOWNLOAD_ERROR } from "features/download/contstants"

async function yandexOAuth({ serviceDomain, oAuthRedirectUri, $setDownloadToCloudStatus }) {
  $setDownloadToCloudStatus(DOWNLOAD_LOADING)
  const isMobileDevice = window.matchMedia("(max-width: 767px)").matches
  const YANDEX_OAUTH_URL = `https://oauth.yandex.ru/authorize?response_type=token&client_id=ef90e60f560b4e8e93519c68158fba3e&display=popup&redirect_uri=${oAuthRedirectUri}&state=${serviceDomain}`
  const OAUTH_WINDOW_WIDTH = isMobileDevice ? screen.width : screen.width / 2
  const OAUTH_WINDOW_HEIGHT = isMobileDevice ? screen.height : screen.height / 1.5
  const LEFT_WINDOW_POSITION = isMobileDevice ? 0 : screen.width / 2 - OAUTH_WINDOW_WIDTH / 2
  const TOP_WINDOW_POSITION = isMobileDevice ? 0 : screen.height / 2 - OAUTH_WINDOW_HEIGHT / 2

  // window parameters
  const OAuthWindowParams = `toolbar=no, menubar=no, width=${OAUTH_WINDOW_WIDTH}, height=${OAUTH_WINDOW_HEIGHT}, top=${TOP_WINDOW_POSITION}, left=${LEFT_WINDOW_POSITION}`
  const OAuthWindow = window.open(YANDEX_OAUTH_URL, "YandexOAuth", OAuthWindowParams)
  const timerId = setInterval(() => {
    if (OAuthWindow?.closed) {
      const token = localStorage.getItem(YANDEX_CLOUD_TOKEN)
      if (!token) {
        $setDownloadToCloudStatus(DOWNLOAD_ERROR)
      }
      clearInterval(timerId)
    }
  }, 300)
}

export { yandexOAuth }
