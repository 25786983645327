import { useDownloadStore } from "features/download/store"

const isDownloadModalVisibleSelector = (state) => state.isDownloadModalVisible
const hideModalSelector = (state) => state.hideDownloadModal
const downloadTargetSelector = (state) => state.downloadTarget
const setDownloadTargetSelector = (state) => state.setDownloadTarget

const downloadToCloudStatusSelector = (state) => state.downloadToCloudStatus
const setDownloadToCloudStatusSelector = (state) => state.setDownloadToCloudStatus
const resetDownloadToCloudStatusSelector = (state) => state.resetDownloadToCloudStatus

const selectedPhotoTypeSelector = (state) => state.selectedPhotoType
const setSelectedPhotoTypeSelector = (state) => state.setSelectedPhotoType
const resetSelectedPhotoTypeSelector = (state) => state.resetSelectedPhotoType

function useDownloadModal() {
  const $isDownloadModalVisible = useDownloadStore(isDownloadModalVisibleSelector)
  const $hideDownloadModal = useDownloadStore(hideModalSelector)
  const $downloadTarget = useDownloadStore(downloadTargetSelector)
  const $setDownloadTarget = useDownloadStore(setDownloadTargetSelector)

  const $downloadToCloudStatus = useDownloadStore(downloadToCloudStatusSelector)
  const $setDownloadToCloudStatus = useDownloadStore(setDownloadToCloudStatusSelector)
  const $resetDownloadToCloudStatus = useDownloadStore(resetDownloadToCloudStatusSelector)

  const $selectedPhotoType = useDownloadStore(selectedPhotoTypeSelector)
  const $setSelectedPhotoType = useDownloadStore(setSelectedPhotoTypeSelector)
  const $resetSelectedPhotoType = useDownloadStore(resetSelectedPhotoTypeSelector)

  return {
    $isDownloadModalVisible,
    $hideDownloadModal,
    $downloadTarget,
    $downloadToCloudStatus,
    $setDownloadToCloudStatus,
    $resetDownloadToCloudStatus,
    $selectedPhotoType,
    $setSelectedPhotoType,
    $resetSelectedPhotoType,
    $setDownloadTarget,
  }
}

export { useDownloadModal }
