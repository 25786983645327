import { useDownloadStore } from "features/download/store"
import { FAVORITE_PHOTOS } from "features/download/contstants"

function useDownloadFavoritePhotos(favoritesListId) {
  const showDownloadModal = useDownloadStore((store) => store.showDownloadModal)
  const setDownloadTarget = useDownloadStore((store) => store.setDownloadTarget)

  function downloadFavoritePhotos() {
    setDownloadTarget({ target: FAVORITE_PHOTOS, id: favoritesListId })
    showDownloadModal()
  }

  return downloadFavoritePhotos
}

export { useDownloadFavoritePhotos }
